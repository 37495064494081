import "./LocationTabs.scss"

import { LocationActions } from "@/entities/location/reducer"
import { useAppDispatch } from "@/store/hooks"

import { LocationActiveTab } from "./locationTabs.types"

import TabButtons from "../components/TabButtons/TabButtons"

export default function LocationTabs() {
  const dispatch = useAppDispatch()

  function handleTabChange(tab: LocationActiveTab) {
    dispatch(LocationActions.change(tab))
  }

  return (
    <div className='locationTabs'>
      <TabButtons handleTabChange={handleTabChange} tab="Locations" />
      <TabButtons handleTabChange={handleTabChange} tab="Scenes" />
    </div>
  )
}
