// ! MenuOn and MenuOff are not available on backend yet, so we'll implement them later
// import MenuOff from "@/assets/MenuOff.svg"
// import MenuOn from "@/assets/MenuOn.svg"
import Checkbox from "@/shared/components/intrinsic/Checkbox/Checkbox"

import { FilteredScenes } from "../../scenes.types"

interface ActiveScreensProps {
  sceneId: string;
  selectedScreenIds: string[];
  onToggleSelect(id: string): void;
  data: FilteredScenes[];
}

function ActiveScreens({ sceneId, data, selectedScreenIds, onToggleSelect }: ActiveScreensProps) {
  function truncateLocation(location: string, maxLength: number): string {
    return location?.length > maxLength ? location.slice(0, maxLength) + "..." : location
  }

  return (
    <>
      {data.filter(screen => screen.scene._id === sceneId).map((screen) => {
        const truncatedLocation = truncateLocation(screen.location, 14)
        const isSelected = selectedScreenIds.includes(screen.id)

        return (
          <div key={screen.id} className='scene__item__screens'>
            <div>
              {/* <img src={screen.active ? MenuOn : MenuOff} alt={screen.active ? "MenuOn" : "MenuOff"} /> */}
              <p>{screen.name}</p>
            </div>
            <div>{truncatedLocation}</div>
            <div>
              <Checkbox 
                checked={isSelected} 
                onChange={() => onToggleSelect(screen.id)} 
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ActiveScreens
