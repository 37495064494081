import "./PopScreenForm.scss"

import { useQueryClient } from "@tanstack/react-query"
import { FormEvent } from "react"
import { useEffect } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm from "react-zod-form"
import { coerce } from "zod"

import SceneDao from "@/entities/scene/scene.dao"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"

const form = new ZodForm({
  title: (
    coerce
      .string()
      .trim()
      .min(1, "Location name must contain at least 1 character")
      .max(50, "Location name must contain at most 50 characters")
  )
})
interface EditSceneFormProps {
  sceneId: string
  companyId: string
}

export default function EditSceneFormForm(props: EditSceneFormProps) {
  const modal = useModalWindow()
  const formStatus = useFormStatus(form, onSubmit)
  const queryClient = useQueryClient()

  const refetchAll = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.companyId] }),
      queryClient.invalidateQueries({ queryKey: ["screens", props.companyId] }),    
      queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] }),
      queryClient.invalidateQueries({
        queryKey: [SceneDao.name, props.sceneId, true],
      })
      
    ])
  }

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await SceneDao.updateName(props.sceneId, fields.title)
    await refetchAll()

    modal.close()
  }

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] })
    queryClient.invalidateQueries({ queryKey: ["screens", props.companyId] })
    queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.companyId] })
    queryClient.invalidateQueries({
      queryKey: [SceneDao.name, props.sceneId, true],
    })
  }, [])

  return (
    <PopupLayout title="Edit Scene Name">
      <form onSubmit={formStatus.submit}>
        <Fields>
          <Field name={form.fields.title} placeholder='Scene name' />
          <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
            <Button color="red" onClick={() => modal.close()}>Cancel</Button>
            <Button type="submit" color="white" {...formStatus}>
              Edit
            </Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}
