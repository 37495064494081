import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { useQueryClient } from "@tanstack/react-query"
import {useEffect,useState } from "react"
import { Location, useLocation } from "react-router-dom"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import AppEditLayout from "@/app/layouts/AppEditLayout/AppEditLayout"
import PageLayout from "@/app/layouts/PageLayout/PageLayout"
import { AppInstanceView } from "@/areas/app"
import { OCBNewAppModal } from "@/areas/company"
import { LocationContainer, LocationGrouped, LocationGroupedComponentProps, LocationTabs } from "@/areas/location"
import LocationHeader from "@/areas/location/header/LocationHeader"
import { OCBSettingsContainer } from "@/areas/ocb"
import { ScenesView } from "@/areas/scene"
import OCBAppDAO from "@/entities/app/ocb/ocb.dao"
import OnlineOCBsSource from "@/entities/app/ocb/ocb.online"
import SceneDao from "@/entities/scene/scene.dao"
import { confirmAction } from "@/services/Modal/components/PopupConfirm"
import Modal from "@/services/Modal/Modal"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import { useAppSelector } from "@/store/hooks"
import useParam from "@/utils/hooks/useParam"
import useResource from "@/utils/hooks/useResource"
import useSearchState from "@/utils/hooks/useSearchState"

interface LocationState {
  dirty?: boolean
}

function OcbsPage() {
  const companyId = useParam("id")
  const [appName] = useSearchState("app")
  const [sceneId, setSceneId] = useState<string>("")
  const locationTab = useAppSelector(state => state.location)

  const location = useLocation() as Location<LocationState>
  location.state ??= {}

  const sidebar = (
    <DefaultBoundaries>
      {!!appName && locationTab === "Locations" && <OCBSettingsContainer name={appName} onChange={() => location.state.dirty = true} />}
      {locationTab === "Scenes" && !!sceneId && ( <OCBSettingsContainer isScene companyId={companyId} name={sceneId} onChange={() => location.state.dirty = true} /> )}
    </DefaultBoundaries>
  )

  return (
    <AppEditLayout sidebar={sidebar} sidebarActive={!!appName || !!sceneId}>
      <PageLayout>
        <LocationHeader companyId={companyId} setSceneId={setSceneId} title="OCB Settings" />
        <LocationTabs />
        <DefaultBoundaries>
          {locationTab === "Locations" ? (
            <LocationGrouped companyId={companyId} children={OCBAppsByLocation} />
          ) : (
            <ScenesView setSceneId={setSceneId} sceneId={sceneId} companyId={companyId} />
          )}
        </DefaultBoundaries>
      </PageLayout>
    </AppEditLayout>
  )
}

export default OcbsPage


const ocbAppDao = new OCBAppDAO

function OCBAppsByLocation(props: LocationGroupedComponentProps) {
  const [appName] = useSearchState("app")

  const { data: ocb } = useQuery({
    queryKey: [ocbAppDao.name, appName],
    queryFn: () => appName ? ocbAppDao.findByName(appName) : null,
  })

  return (
    <LocationContainer {...props} defaultExpanded={props.location.id === ocb?.locationId}>
      <DefaultBoundaries>
        <OCBAppsContainer {...props} />
      </DefaultBoundaries>
    </LocationContainer>
  )
}

function OCBAppsContainer(props: LocationGroupedComponentProps) {
  const [appName, setAppName] = useSearchState("app")
  const location = useLocation() as Location<LocationState>
  const queryClient = useQueryClient()

  const queryKey = [ocbAppDao.name, "location", props.location.id]
  const { data: ocbs } = useSuspenseQuery({
    queryFn: () => ocbAppDao.findByLocation(props.location.id),
    queryKey
  })

  const activeAppIds = useResource(() => new OnlineOCBsSource(props.companyId), [props.companyId], [])

  async function onEdit(value: string) {
    if (location.state?.dirty) {
      await confirmAction()
    }

    setAppName(value)
  }

  function onCreate() {
    Modal.open(OCBNewAppModal, { companyId: props.companyId, location: props.location })
  }

  const refetchAll = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.companyId] }),
      queryClient.invalidateQueries({ queryKey: ["screens", props.companyId] }),    
      queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] }),
      queryClient.invalidateQueries({ queryKey: [ocbAppDao.name, "location", props.location.id] })
    ])
  }

  useEffect(() => {
    refetchAll()
  }, [])

  return (
    <>
      <ButtonIcon size="small" name="plus" ariaLabel="Create new App" onClick={onCreate} />
      {ocbs.length === 0 && (
        <span>There are no OCBs in this location.</span>
      )}
      {ocbs.toReversed().map(ocb => (
        <AppInstanceView {...ocb} scene={ocb.scene} active={activeAppIds.includes(ocb.name)} editing={ocb.name === appName} onEdit={() => onEdit(ocb.name)} key={ocb.name} />
      ))}
    </>
  )
}
