/* eslint-disable @typescript-eslint/no-explicit-any */

import { StreamAPI } from "@/api/stable/APIStable"
import FileUtils from "@/utils/transform/file"
import FormDataEnhanced from "@/utils/transform/FormDataEnhanced"

import { Scene } from "./scene.types"
import { UpdateScene } from "./scene.types"

import DAO from "../dao"
import ToastResult from "../ToastResult.decorator"

class SceneDAO extends DAO {
  @ToastResult("Scene", "Create", "Created")
  async create(name: string, company: string, orderScreen: any, welcomeScreen: any): Promise<any> {
    const response = await StreamAPI.fetch.POST["/scenes"]({
      body: { name, company, orderScreen, welcomeScreen }
    })

    const location = response.payload
    return location
  }

  async find(companyId: string): Promise<Scene[]> {
    const response = await StreamAPI.fetch.GET[`/scenes/company/{companyId}`]({
      pathParams: { companyId },
    })

    const transformedScenes = await Promise.all(
      response.payload.map(async (scene: any) => {
        const views = {
          order: scene.orderScreen,
          welcome: scene.welcomeScreen,
        }

        const [order, welcome] = await Promise.all([
          FileUtils.resolveURLs(views.order ?? {}),
          FileUtils.resolveURLs(views.welcome ?? {}),
        ])
  
        return {
          ...scene,
          views: {
            order,
            welcome,
          },
        }
      }),
    )
    return transformedScenes
  }
  
  


  @ToastResult("Scene", "Update", "Updated")
  async update(sceneId: string, data: UpdateScene) {
    const order = data.orderScreen
    const welcome = data.welcomeScreen


    const { backgrounds: welcomeBackgrounds, logo: welcomeLogo, video: welcomeVideo, ...welcomeRest } = welcome ?? {}
    const { backgrounds: orderBackgrounds, logo: orderLogo, ...orderRest } = order ?? {}

    const formData = new FormDataEnhanced({
      // General
      name: data.name,
      company: data.company,
    
      // Welcome View
      welcomeScreen: welcomeRest,
      logo: welcomeLogo,
      video: welcomeVideo,
      welcome: welcomeBackgrounds,
    
      // Order View
      orderScreen: orderRest,
      orderLogo,
      order: orderBackgrounds,
    })
    

    await formData.renameFilesToHashes()

    const response = await StreamAPI.fetch.PATCH[`/scenes/{sceneId}`]({
      pathParams: { sceneId },
      body: formData
    })
    return response.payload
  }

  @ToastResult("Screen", "Assignment", "success")
  async assign(
    ...args: [string, string[], string[]]
  ): Promise<any> {
    const [sceneId, ocbIdsToAssign, ocbIdsToDetach] = args
    const response = await StreamAPI.fetch.POST[`/ocbapp/scenes/assign/{sceneId}`]({
      pathParams: { sceneId },
      body: { ocbIdsToAssign, ocbIdsToDetach }
    })
    return response.payload
  }


  @ToastResult("Scene", "Delete", "Deleted")
  async delete(sceneId: string) {
    await StreamAPI.fetch.DELETE[`/scenes/name/{sceneId}`]({
      pathParams: { sceneId }
    })
  }

  @ToastResult("Scene", "Rename", "Renamed")
  async updateName(sceneId: string, name: string) {
    const response = await StreamAPI.fetch.PATCH[`/scenes/name/{sceneId}`]({
      pathParams: { sceneId },
      body: { name }
    })
    return response.payload
  }
}

const SceneDao = new SceneDAO()
export default SceneDao
