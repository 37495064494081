import "./ExpandedButton.scss"

import { useState } from "react"

import Add from "@/assets/PlusIcon.svg"

interface ExpandedButtonProps {
  onClick: () => void
  name: string
}

export default function ExpandedButton(props: ExpandedButtonProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  return (
    <div>
      <button
        onClick={props.onClick}
        className="expandedButton__createBtn"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img src={Add} alt="Add" />
        {isHovered && <span className="expandedButton__hoverText">{props.name}</span>}
      </button>
    </div>
  )
}
