import { useSuspenseQuery } from "@tanstack/react-query"
import { ReactElement } from "react"

import LocationDao from "@/entities/location/location.dao"
import { QLocation } from "@/entities/location/location.types"

import LocationViews from "../components/LocationViews/LocationViews"

export interface LocationGroupedComponentProps {
  companyId: string
  location: QLocation
}

interface LocationGroupedProps {
  companyId: string
  children(props: LocationGroupedComponentProps): ReactElement
}

function LocationGrouped(props: LocationGroupedProps) {

  const { data: locations } = useSuspenseQuery({
    queryFn: () => LocationDao.findAll({ companyId: props.companyId }),
    queryKey: [LocationDao.name, props.companyId],
  })

  return (
    <LocationViews>
      {locations.toReversed().map(location => (
        <props.children companyId={props.companyId} location={location} key={location.id} />
      ))}
    </LocationViews>
  )
}

export default LocationGrouped
