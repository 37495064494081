import "./PopScreenForm.scss"

import { useQueryClient } from "@tanstack/react-query"
import { FormEvent } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useModalWindow } from "react-modal-global"

import SceneDao from "@/entities/scene/scene.dao"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Fields from "@/shared/layouts/Fields/Fields"

interface DetachSceneFormProps {
  sceneId: string
  companyId: string
  selectedIds: string[]
}

export default function DetachSceneForm(props: DetachSceneFormProps) {
  const modal = useModalWindow()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const refetchAll = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.companyId] }),
      queryClient.invalidateQueries({ queryKey: ["screens", props.companyId] }),    
      queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] }) 
    ])
  }

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setIsLoading(true)
    await SceneDao.assign(props.sceneId, [], props.selectedIds)
    await refetchAll()
    setIsLoading(false)
    modal.close()
  }

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] })
    queryClient.invalidateQueries({ queryKey: ["screens", props.companyId] })
    queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.companyId] })
  }, [])

  return (
    <PopupLayout title="Detach OCBs">
      <form onSubmit={onSubmit}>
        <Fields>
          <h4 style={{ marginBottom: "2rem" }}>Are you sure you wanna detach the selected OCBs from this Scene?</h4>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
            <Button color="red" onClick={() => modal.close()}>Cancel</Button>
            <Button type="submit" color="white" await pending={isLoading} >
              Detach
            </Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}
