import { useEffect,useRef, useState } from "react"

import Menu from "@/assets/Menu.svg"
import Add from "@/assets/PlusIcon.svg"
import { Scene } from "@/entities/scene/scene.types"
import Checkbox from "@/shared/components/intrinsic/Checkbox/Checkbox"

interface SceneHeaderProps {
  scene: Scene;
  handleConfigure(): void;
  openModal(): void;
  handleDeleteScene(): void;
  handleSelectAll(): void;
  handleDeleteSelectedScreens(): void;
  areAllSelected: boolean;
  selectedScreenIds: string[];
  haveScreens: boolean;
}

export default function SceneHeader(props: SceneHeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className='scene__item__top' style={{ position: "relative" }}>
      <div className='scene__item__top__header'>
        <p className='scene__item__top__header__label'>{props.scene.name}</p>
        <button className='scene__item__top__header__edit' onClick={props.handleConfigure}>Edit</button>
        <button onClick={props.openModal} className='scene__item__top__header__add'>
          <img src={Add} alt="Add" />
        </button>
      </div>
      <div className='scene__item__top__menu' style={{ position: "relative", display: "flex", alignItems: "center", gap: "0.5rem" }}>
        {!props.haveScreens && (
          <>
            <button onClick={props.handleSelectAll}>Select All</button>
            <Checkbox 
              checked={props.areAllSelected} 
              onChange={props.handleSelectAll}
              title="Select/Unselect All Screens"
            />
          </>
        )}
        
        <button className='scene__item__top__menu__btn' onClick={toggleMenu}>
          <img src={Menu} alt="Menu" />
        </button>
        {isMenuOpen && (
          <div 
            ref={menuRef} 
            className='scene__item__top__menu__openedMenu'
          >
            <button 
              className='scene__item__top__menu__openedMenu__btn'
              onClick={() => {
                props.handleDeleteScene()
                setIsMenuOpen(false)
              }}
            >
              Delete Scene
            </button>

            {props.scene && props.selectedScreenIds.length > 0 && (
              <button 
                className='scene__item__top__menu__openedMenu__btn'
                onClick={() => {
                  props.handleDeleteSelectedScreens()
                  setIsMenuOpen(false)
                }}
              >
                {props.selectedScreenIds.length === 1 ? "Remove Screen" : "Remove Screens"}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
