// ! This is for mocking scenes, should be removed when we have real data

// eslint-disable-next-line no-restricted-imports
import { InferActions } from "@/store/store.types"

import { Types } from "./scene.actions"

import { Scenes } from "../scene.types"

const initialState: Scenes[] = []

export default (state = initialState, action: InferActions<Types>): Scenes[] => {
  switch (action.type) {
    case "SCENES_CHANGE":
      return [...state, action.payload]

    case "SCENES_RESET":
      return initialState

    case "SCENES_UPDATE":
      return state.map(scene => {
        if (scene.id === action.payload.id) {
          return action.payload
        }
        return scene
      })

    case "SCENES_REMOVE":
      return state.filter(scene => scene.id !== action.payload)
        

    default:
      return state
  }
}
