import "./SceneItem.scss"

import { useQueryClient } from "@tanstack/react-query"
import { useEffect,useState } from "react"

import { Scene } from "@/entities/scene/scene.types"
import Modal from "@/services/Modal/Modal"

import ActiveScreens from "./components/ActiveScreens"
import AddScreen from "./components/AddScreen"
import SceneHeader from "./components/SceneHeader"

import DeleteSceneForm from "../modals/DeleteSceneForm"
import DetachSceneForm from "../modals/DetachSceneForm"
import PopScreenForm from "../modals/PopScreenForm"
import { FilteredScenes } from "../scenes.types"
import { SceneOptions } from "../scenes.types"

interface SceneItemProps {
  scene: Scene
  setSceneId(sceneId: string): void
  sceneId: string
  companyId: string
  screens: FilteredScenes[]
  sceneOptions: SceneOptions[]
}


export default function SceneItem(props: SceneItemProps) {
  const [selectedScreenIds, setSelectedScreenIds] = useState<string[]>([])
  const [areAllSelected, setAreAllSelected] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const openModal = async () => {
    await queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] }) 
    Modal.open(PopScreenForm, { sceneId: props.scene._id, sceneOptions: props.sceneOptions, companyId: props.companyId })
  }

  const handleConfigure = async () => {
    props.setSceneId(props.scene._id)
  }

  const handleDeleteScene = async () => {
    Modal.open(DeleteSceneForm, { sceneId: props.scene._id, companyId: props.companyId })
  }

  const handleSelectAll = () => {
    const allScreenIds = props.screens
      .filter(screen => screen.scene._id === props.scene._id)
      .map(screen => screen.id) 
  
    const isAllSelected = allScreenIds.every(id => selectedScreenIds.includes(id))
  
    if (isAllSelected) {
      setSelectedScreenIds(prev => prev.filter(id => !allScreenIds.includes(id)))
      setAreAllSelected(false)
    } else {
      setSelectedScreenIds(prev => [...new Set([...prev, ...allScreenIds])])
      setAreAllSelected(true)
    }
  }
  
  const handleSingleSelect = (id: string) => {
    if (selectedScreenIds.includes(id)) {
      setSelectedScreenIds(selectedScreenIds.filter(sId => sId !== id))
    } else {
      setSelectedScreenIds([...selectedScreenIds, id])
    }
  }

  const handleDeleteSelectedScreens = async () => {
    Modal.open(DetachSceneForm, { sceneId: props.scene._id, companyId: props.companyId, selectedIds: selectedScreenIds })
  }

  useEffect(() => {
    const allScreenIds = props.screens
      .filter(screen => screen.scene._id === props.scene._id)
      .map(screen => screen.id)
  
    setAreAllSelected(allScreenIds.every(id => selectedScreenIds.includes(id)))
  }, [selectedScreenIds, props.screens, props.scene._id])

  const haveScreens = props.screens.filter(screen => screen.scene._id === props.scene._id).length === 0

  return (
    <div className='scene__item'>
      <SceneHeader 
        scene={props.scene} 
        handleConfigure={handleConfigure} 
        openModal={openModal} 
        handleDeleteScene={handleDeleteScene} 
        handleSelectAll={handleSelectAll}
        handleDeleteSelectedScreens={handleDeleteSelectedScreens}
        selectedScreenIds={selectedScreenIds}
        areAllSelected={areAllSelected}
        haveScreens={haveScreens}
      />

      {props.scene && (
        haveScreens? (
          <AddScreen scene={props.scene} openModal={openModal} />
        ) : (
          <ActiveScreens 
            sceneId={props.sceneId} 
            selectedScreenIds={selectedScreenIds} 
            onToggleSelect={handleSingleSelect}
            data={props.screens}
          />
        )
      )}
    </div>
  )
}
