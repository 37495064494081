import { SceneOptions } from "../scenes.types"

interface ScreenOptionsProps {
  selectedIds: string[]
  setSelectedIds: (ids: string[]) => void
  allItems: SceneOptions[]
}

export default function ScreenOptions(props: ScreenOptionsProps) {
  const isAllSelected = props.selectedIds.length === props.allItems.length

  
  const toggleSelectAll = () => {
    if (isAllSelected) {
      props.setSelectedIds([])
    } else {
      props.setSelectedIds(props.allItems.map(item => item.id))
    }
  }
  
  const handleSelectItem = (id: string) => {
    if (props.selectedIds.includes(id)) {
      props.setSelectedIds(props.selectedIds.filter(selectedId => selectedId !== id))
    } else {
      props.setSelectedIds([...props.selectedIds, id])
    }
  }
  
  return (
    <div className='screens-options'>
      {props.allItems.length > 0 ? (
        <>
          <button onClick={toggleSelectAll} type="button">
            {isAllSelected ? "Unselect All" : "Select All"}
          </button>

          {props.allItems.map((item, idx) => {
            const isSelected = props.selectedIds.includes(item.id)
        
            const isNewLocationSection = 
          idx === 0 || item.location !== props.allItems[idx - 1].location

            return (
              <div key={item.id}>
                {isNewLocationSection && (
                  <p className='screens-options__location'>{item.location}</p>
                )}
                <button
                  name={item.id}
                  style={{
                    backgroundColor: isSelected ? "rgba(92, 117, 56, 0.5)" : "transparent"
                  }}
                  className='screens-options__item'
                  onClick={() => handleSelectItem(item.id)}
                  type="button"
                >
                  {item.name}
                </button>
              </div>
            )
          })}
        </>
      ) : (
        <p>No screens available!!!</p>
      )}
    </div>
  )
}
