import Add from "@/assets/PlusIcon.svg"
import { Scene } from "@/entities/scene/scene.types"

interface SceneItemProps {
  scene: Scene
  openModal(): void
}

export default function AddScreen(props: SceneItemProps) {
  return (
    <div className='scene__item__add'>
      <p>Add Screen</p>

      <button onClick={props.openModal}>
        <img src={Add} alt="Add" />
      </button>

      <form className='w-1/2 mx-auto'></form>
    </div> 
  )
}
