import "./TabButtons.scss"

import { useAppSelector } from "@/store/hooks"

import { LocationActiveTab } from "../../tabs/locationTabs.types" 

interface TabButtonsProps {
  handleTabChange: (tab: LocationActiveTab) => void
  tab: LocationActiveTab
}

export default function TabButtons(props: TabButtonsProps) {
  const location = useAppSelector(state => state.location)

  return (
    <button 
      onClick={() => props.handleTabChange(props.tab)} 
      className={`locationTabs__btn ${location === props.tab && "locationTabs__btn--active"}`}
    >
      {props.tab}
    </button>
  )
}
