// eslint-disable-next-line no-restricted-imports
import { LocationActiveTab } from "@/areas/location/tabs/locationTabs.types"

export interface Types {
  LOCATION_TAB_CHANGE: LocationActiveTab
  LOCATION_TAB_RESET: void
}

export const change = (payload: Types["LOCATION_TAB_CHANGE"]) => ({
  type: "LOCATION_TAB_CHANGE",
  payload
}) as const

export const reset = () => ({
  type: "LOCATION_TAB_RESET"
}) as const
