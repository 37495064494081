import { useQueryClient } from "@tanstack/react-query"
import { FormEvent } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce } from "zod"

import SceneDao from "@/entities/scene/scene.dao"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"

const form = new ZodForm({
  title: (
    coerce
      .string()
      .trim()
      .min(1, "Location name must contain at least 1 character")
      .max(50, "Location name must contain at most 50 characters")
  )
})

interface PopupSceneFormProps {
  defaultValue?: string
  onSubmit?(value: string): void | Promise<void>
  setSceneId(sceneId: string): void
  company: string
}

function PopupSceneForm(props: PopupSceneFormProps) {
  const modal = useModalWindow()
  const formStatus = useFormStatus(form, onSubmit)
  const { fieldIssues } = useZodFormIssues(form)
  const queryClient = useQueryClient()


  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    const orderScreen = {
      "fontFamily": "Helvetica",
      "primaryTextSize": 20,
      "primaryTextColor": "#ff0000",
      "secondaryTextSize": 12,
      "secondaryTextColor": "#575757",
      "layout": 0,
      "primaryColor": "#FFFFFF",
      "secondaryColor": "#FFFFFF",
      "backgrounds": [],
      "logo": ""
    }

    const welcomeScreen = {
      "backgrounds": [],
      "logo": null,
      "video": null,
      "title": "Welcome Screen",
      "theme": false,
      "fontFamily": "Verdana",
      "fontColor": "#5256ff",
      "fontSize": 126,
      "orderTimeout": 30,
      "totalScreenEnabled": false,
      "totalScreenTimeout": 5
    }

    const res = await SceneDao.create(fields.title, props.company, orderScreen, welcomeScreen)
    await queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.company] })
    props.setSceneId(res._id)

    modal.close()
  }


  function onChange(event: FormEvent<HTMLFormElement>) {
    form.parseAllFields(event)
  }

  return (
    <PopupLayout title="Scene">
      <form onSubmit={formStatus.submit} onChange={onChange}>
        <Fields>
          <Field name={form.fields.title} customValidity={fieldIssues.title} defaultValue={props.defaultValue} placeholder='Scene name' />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit" color="white" {...formStatus}>{props.defaultValue == null ? "Create" : "Update"}</Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default PopupSceneForm
