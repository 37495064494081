import "./LocationHeader.scss"

import { useSuspenseQuery } from "@tanstack/react-query"
import { Link } from "react-router-dom"

// eslint-disable-next-line no-restricted-imports
import PopupLocationForm from "@/areas/location/modals/PopupLocationForm"
import { PopupSceneForm } from "@/areas/scene"
import LocationDao from "@/entities/location/location.dao"
import { UserRole } from "@/entities/user"
import Modal from "@/services/Modal/Modal"
import ExpandedButton from "@/shared/components/intrinsic/ExpandedButton/ExpandedButton"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { useAppSelector } from "@/store/hooks"

interface LocationHeaderProps {
  companyId: string;
  setSceneId?(sceneId: string): void;
  title: string;
}

export default function LocationHeader(props: LocationHeaderProps) {
  const { user, location } = useAppSelector((state) => state)
  const url = `/companies?company-id=${props.companyId}`

  const { refetch } = useSuspenseQuery({
    queryFn: () => LocationDao.findAll({ companyId: props.companyId }),
    queryKey: [LocationDao.name, props.companyId],
  })

  async function onCreate(name: string) {
    await LocationDao.create(name, props.companyId)
    await refetch()
  }

  async function onCreateScene() {
    await refetch()
  }

  return (
    <>
      <div className="profile-settings-layout__header">
        <Link className="profile-settings-layout__link" to={url}>
          <Icon name="chevron-left" />
          Back to company selection
        </Link>
      </div>

      <div className="locationHeader">
        <h2 className="locationHeader__title">{props.title}</h2>
        {location === "Locations" || props.title === "Menu Settings" ? user.role >= UserRole.CompanyOwner && (
          <ExpandedButton onClick={() => Modal.open(PopupLocationForm, { onSubmit: onCreate })} name="Create Location" />
        ) : 
          <ExpandedButton onClick={() => Modal.open(PopupSceneForm, { onSubmit: onCreateScene, company: props.companyId, setSceneId: props.setSceneId ?? (() => {}) })} name="Create Scene" />
        }
      </div>
    </>
  )
}
