import "./PopScreenForm.scss"

import { useQueryClient } from "@tanstack/react-query"
import { FormEvent } from "react"
import { useEffect,useState } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm from "react-zod-form"
import { coerce } from "zod"

import SceneDao from "@/entities/scene/scene.dao"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"
import useSearchState from "@/utils/hooks/useSearchState"

import { SceneOptions } from "../scenes.types"
import ScreenOptions from "../ScreenOptions/ScreenOptions"

const form = new ZodForm({
  title: (
    coerce
      .string()
      .trim()
      .min(1, "Location name must contain at least 1 character")
      .max(50, "Location name must contain at most 50 characters")
  )
})
interface PopupSceneFormProps {
  defaultValue?: string
  sceneId: string
  sceneOptions: SceneOptions[]
  companyId: string
}

export default function PopScreenForm(props: PopupSceneFormProps) {
  const modal = useModalWindow()
  const formStatus = useFormStatus(form, onSubmit)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const queryClient = useQueryClient()
  const [, setAppName] = useSearchState("app")

  const refetchAll = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [SceneDao.name, props.companyId] }),
      queryClient.invalidateQueries({ queryKey: ["screens", props.companyId] }),    
      queryClient.invalidateQueries({ queryKey: ["sceneOptions", props.companyId] }) 
    ])
  }


  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
  
    const fields = form.parseAllFields(event)
    if (!fields) return
  
    await SceneDao.assign(props.sceneId, selectedIds, [])
    await refetchAll()

    setAppName("")

    setSelectedIds([])
    modal.close()
  }

  useEffect(() => {
    refetchAll()
  }, [modal])

  return (
    <PopupLayout title="Add Screen">
      <form onSubmit={formStatus.submit}>
        <Fields>
          <ScreenOptions selectedIds={selectedIds} setSelectedIds={setSelectedIds} allItems={props.sceneOptions} />
          <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
            <Button color="red" onClick={() => modal.close()}>Cancel</Button>
            <Button type="submit" color="white" {...formStatus}>
              {props.defaultValue == null ? "Assign" : "Update"}
            </Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}
