import { OrderViewLayout } from "@streamllc/shared/ocb"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import EditSceneForm from "@/areas/scene/modals/EditSceneForm"
import OCBAppDAO from "@/entities/app/ocb/ocb.dao"
import { OCBAppInstance, OCBAppViews } from "@/entities/app/ocb/ocb.types"
import SceneDao from "@/entities/scene/scene.dao"
import { UpdateScene } from "@/entities/scene/scene.types"
import { confirmAction } from "@/services/Modal/components/PopupConfirm"
import Modal from "@/services/Modal/Modal"

import OCBSettings from "./OCBSettings"

import ocbViewsContext from "../../contexts/ocbViewsContext"

const ocbAppDao = new OCBAppDAO

interface OCBSettingsContainerProps {
  name: string
  onChange?(): void
  isScene?: boolean
  companyId?: string
}

function OCBSettingsContainer(props: OCBSettingsContainerProps) {
  const { data, isRefetching, refetch } = useSuspenseQuery({
    queryFn: async () => {
      if (props.isScene && props.companyId) {
        const scenes = await SceneDao.find(props.companyId)
        return (
          scenes.find(scene => scene._id === props.name) || {}
        )
      } else {  
        return (await ocbAppDao.findByName(props.name)) || {}
      }
    },
    queryKey: [
      props.isScene ? SceneDao.name : ocbAppDao.name,
      props.name,
      props.isScene ?? false,
    ],
  })

  const isSceneData = props.isScene && Array.isArray(data)
  const ocb = !isSceneData ? (data as OCBAppInstance) : undefined
  const isScene = props.isScene && props.companyId && ocb?.name

  const [views, setViews] = useState<Partial<OCBAppViews>>(() => getInitialViews(ocb!))
  useEffect(() => {
    if (ocb) setViews(getInitialViews(ocb))
  }, [ocb?.name])

  async function onDelete() {
    await confirmAction()
    await ocbAppDao.delete(props.name)
  }

  async function onPublish() {
    if(isScene) {
      const allViews = views as OCBAppViews
      const body: UpdateScene = {
        name: ocb.name,
        company: props.companyId as string,
        orderScreen: allViews.order,
        welcomeScreen: allViews.welcome
      }

      await SceneDao.update(props.name, body)
      await refetch()
    } else {
      await ocbAppDao.update(props.name, { views })
      await refetch()
    }
  }

  function setViewsOverridden(...args: Parameters<typeof setViews>) {
    setViews(...args)
    props.onChange?.()
  }

  function openModal () {
    if(props.companyId) Modal.open(EditSceneForm, { sceneId: props.name, companyId: props.companyId })
  }

  return (
    <ocbViewsContext.Provider value={[views, setViewsOverridden]}>
      <OCBSettings name={ocb?.name ?? props.name} onDelete={onDelete} onPublish={onPublish} isScene={!!isScene} openModal={openModal} />
      <div style={{ background: "white", opacity: Number(isRefetching), transition: "200ms ease opacity" }} />
    </ocbViewsContext.Provider>
  )
}

export default OCBSettingsContainer


function getInitialViews(ocb: OCBAppInstance) {
  if (ocb.empty) return DEFAULT_SETTINGS

  return ocb.views
}

const DEFAULT_SETTINGS: Partial<OCBAppViews> = {
  welcome: {
    title: "OCB Settings",
    backgrounds: [],
    fontSize: 42,
    fontFamily: "Verdana",
    fontColor: "white",

    totalScreenEnabled: false
  },
  order: {
    primaryTextSize: 34,
    secondaryTextSize: 28,

    fontFamily: "Helvetica",

    backgrounds: [],
    layout: OrderViewLayout.Layout1,

    primaryTextColor: "white",
    secondaryTextColor: "#bcbcbc",

    primaryColor: "#575757",
    secondaryColor: "#777777"
  }
} as const

Object.seal(DEFAULT_SETTINGS)
Object.freeze(DEFAULT_SETTINGS)
