/* eslint-disable @typescript-eslint/no-explicit-any */
import "./ScenesView.scss"

import { useSuspenseQuery } from "@tanstack/react-query"

import OCBAppDAO from "@/entities/app/ocb/ocb.dao"
import LocationDao from "@/entities/location/location.dao"
import SceneDao from "@/entities/scene/scene.dao"

import SceneItem from "../SceneItem/SceneItem"
import { FilteredScenes,SceneOptions } from "../scenes.types"

const ocbAppDao = new OCBAppDAO

interface ScenesViewProps {
  setSceneId(sceneId: string): void
  sceneId: string
  companyId: string
}

export default function ScenesView(props: ScenesViewProps) {
  const { data } = useSuspenseQuery({
    queryFn: async () => {
      try {
        const scenes = await SceneDao.find(props.companyId)
        return scenes || []
      } catch (error) {
        return []
      }
    },
    queryKey: [SceneDao.name, props.companyId],
  })

  const { data: screens } = useSuspenseQuery({
    queryFn: async () => {
      const locations = await LocationDao.findAll({ companyId: props.companyId })
  
      const ocbAppsArray = await Promise.all(
        locations.map(async (loc) => {
          const ocbApps = await ocbAppDao.findByLocation(loc.id)
  
          const result = ocbApps.map(app => ({
            ...app,
            location: loc.title,
            scene: app.scene && (app.scene as any)._id
              ? {
                ...((app.scene as any) || {}),
                _id: typeof (app.scene as any)._id === "object"
                  ? (app.scene as any)._id._id
                  : (app.scene as any)._id,
              }
              : null,
          }))
  
          return result.filter(app => !!app.scene)
        })
      )
  
      const filteredApps = ocbAppsArray.flat()
      return filteredApps as FilteredScenes[]
    },
    queryKey: ["screens", props.companyId],
  })
  
  

  const { data: sceneOptions } = useSuspenseQuery({
    queryFn: async () => {
      const locations = await LocationDao.findAll({ companyId: props.companyId })

      const ocbAppsArray = await Promise.all(
        locations.map(async (loc) => {
          const ocbApps = await ocbAppDao.findByLocation(loc.id)
          const result = ocbApps.map(app => ({ ...app, location: loc.title, active: false }))
          
          return result.filter(app => !app.scene)
        })
      )

      const filteredApps = ocbAppsArray.flat()
      return filteredApps as SceneOptions[]
    },
    queryKey: ["sceneOptions", props.companyId],
  })

  const uniqueScenesIds = Array.from(new Set(data.map(item => item._id)))

  return (
    <div className='scenes'>
      {uniqueScenesIds.length === 0 ? (
        <div className='scenes__no_scenes'>
          <h3>Currently, there are no Scenes available.</h3>
          <p>Use the plus button to create one if you’re allowed, or contact your admin for support.</p>
        </div>
      ) : (
        uniqueScenesIds.map(sceneId => {
          return (
            <div key={sceneId} className='scene__group'>
              {data
                .filter((scene) => scene._id === sceneId) 
                .map((scene, i) => (
                  <SceneItem
                    key={i}
                    sceneOptions={sceneOptions}
                    screens={screens} 
                    scene={scene}
                    setSceneId={props.setSceneId}
                    sceneId={sceneId}
                    companyId={props.companyId}
                  />
                ))}
            </div>
          )
        })
      )}
    </div>
  )
}
