import { locationReducer } from "@/entities/location/reducer"
import { sceneReducer } from "@/entities/scene/reducer"
import { userReducer } from "@/entities/user/reducer"

export default {
  user: userReducer,
  location: locationReducer,
  // ! This is for mocking scenes, should be removed when we have real data
  scene: sceneReducer,
}
