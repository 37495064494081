// eslint-disable-next-line no-restricted-imports
import { LocationActiveTab } from "@/areas/location/tabs/locationTabs.types"
import { InferActions } from "@/store/store.types"

import { Types } from "./location.actions"

const initialState: LocationActiveTab = "Locations"

export default (state = initialState, action: InferActions<Types>): LocationActiveTab => {
  switch (action.type) {

    case "LOCATION_TAB_CHANGE":
      return action.payload

    case "LOCATION_TAB_RESET":
      return initialState

    default:
      return state
  }
}
